import { Alert, Box, Snackbar } from "@mui/material";
import { Button } from "@mui/material";
import { bnb, cardLogo, eth, usdt } from "./Images";

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={10000}
      key={"top center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
        sx={{ fontFamily: "Kanit" }}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}

export function StyledButton({ children, ...props }) {
  return (
    <>
      <Button
        {...props}
        sx={{
          color: "#ffffff",
          background: "rgb(37, 99, 235)",
          fontSize: "18px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          textTransform: "capitalize",
          //   fontFamily: "Josefin Sans",
          borderRadius: "12px",
          width: props.width,
          "&.Mui-disabled": {
            color: "#979EA7",
          },
          "&:hover": {
            background: "rgb(37, 99, 235)",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}
export function StyledCurrencyButton({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: props.color,
          background: props.bgcolor,
          fontSize: { xs: "15px", sm: "17px" },
          borderRadius: "8px",
          display: "flex",
          px: 1.5,
          py: 0.5,
          alignItems: "center",
          justifyContent: "center",
          mr: 1,
          cursor: "pointer",
        }}
      >
      
        <Box
          component="img"
          width={{ xs: "20px", sm: "25px" }}
          style={{ marginRight: "5px" }}
          src={
            children === "BNB"
              ? bnb
              : children === "ETH"
              ? eth
              : children === "CARD"
              ? cardLogo
              : usdt
          }
          alt=""
        />{" "}
        {children}
      </Box>
    </>
  );
}
export function StyledText({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "gray",
          fontSize: "18px",
          //   fontFamily: "Josefin Sans",
          fontWeight: "500",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}
export function StyledTitle({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#000000",
          fontSize: "40px",
          //   fontFamily: "Josefin Sans",
          fontWeight: "700",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}
