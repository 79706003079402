import React from 'react'

function Partners() {
    return (
        <div className="section-2 wf-section">
            <div className="slider-wrapper">
                <div className="items-holder">
                    <div className="items">
                        <a
                            className="item"
                            href="https://www.financebrokerage.com/surf-reward-ico-revolutionizing-digital-advertising/"
                            target="_blank"
                        >
                            <img
                                src="images/pr/finance_brokerage.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://news.bitcoin.com/surf-reward-launches-browser-extension-with-cryptocurrency-rewards-and-ido-presale/"
                            target="_blank"
                        >
                            <img
                                src="images/pr/bitcoin_com_farbe2.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://ambcrypto.com/surf-reward-launches-browser-extension-with-cryptocurrency-rewards-and-ido-presale/"
                            target="_blank"
                        >
                            <img
                                src="images/pr/amb_crypto_farbe3.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://bitcoinist.com/surf-reward-launches-browser-extension-with-cryptocurrency-rewards-ido-presale/"
                            target="_blank"
                        >
                            <img
                                src="images/pr/bitcoinist_farbe.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://www.newsbtc.com/press-releases/surf-reward-launches-browser-extension-with-cryptocurrency-rewards-ido-presale/?swcfpc=1"
                            target="_blank"
                        >
                            <img
                                src="images/pr/newsbtc_farbe.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://www.coinspeaker.com/surf-reward-browser-extension-cryptocurrency-rewards-ido-presale/"
                            target="_blank"
                        >
                            <img
                                src="images/pr/coinspeaker_farbe2.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://www.digitaljournal.com/pr/news/surf-reward-launches-browser-extension-with-cryptocurrency-rewards-ido-presale"
                            target="_blank"
                        >
                            <img
                                src="images/pr/digital_journal_farbe.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://www.marketwatch.com/press-release/surf-reward-launches-browser-extension-with-cryptocurrency-rewards-ido-presale-2023-02-17?mod=search_headline"
                            target="_blank"
                        >
                            <img
                                src="images/pr/marketwatch_farbe2.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://finance.yahoo.com/news/surf-reward-launches-browser-extension-183100324.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmRlLw&guce_referrer_sig=AQAAADRUn8VJIeXGbgMkx_cN34C9mSxIM9imYWIVI9hBjlckWF8a2QR5sXEGXFkEKpeJKoq4SGadMeqN-4uLyoe5NANFVG-g3ouSyvMdnFnOhlhTALnxWnoGfaieEi43Fxa1mvQx88WVuIDHsx1NQkkuiQLGLfkZwnyLEFx5Iwx_8Hn3"
                            target="_blank"
                        >
                            <img
                                src="images/pr/yahoo_finance_farbe.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://zycrypto.com/abelius-capital-unveils-its-surf2earn-web-browser-extension-surf-reward-and-ido-plans/"
                            target="_blank"
                        >
                            <img
                                src="images/pr/zycrypto_farbe.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                    </div>
                    <div className="items">
                        <a
                            className="item"
                            href="https://www.financebrokerage.com/surf-reward-ico-revolutionizing-digital-advertising/"
                            target="_blank"
                        >
                            <img
                                src="images/pr/finance_brokerage.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://news.bitcoin.com/surf-reward-launches-browser-extension-with-cryptocurrency-rewards-and-ido-presale/"
                            target="_blank"
                        >
                            <img
                                src="images/pr/bitcoin_com_farbe2.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://ambcrypto.com/surf-reward-launches-browser-extension-with-cryptocurrency-rewards-and-ido-presale/"
                            target="_blank"
                        >
                            <img
                                src="images/pr/amb_crypto_farbe3.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://bitcoinist.com/surf-reward-launches-browser-extension-with-cryptocurrency-rewards-ido-presale/"
                            target="_blank"
                        >
                            <img
                                src="images/pr/bitcoinist_farbe.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://www.newsbtc.com/press-releases/surf-reward-launches-browser-extension-with-cryptocurrency-rewards-ido-presale/?swcfpc=1"
                            target="_blank"
                        >
                            <img
                                src="images/pr/newsbtc_farbe.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://www.coinspeaker.com/surf-reward-browser-extension-cryptocurrency-rewards-ido-presale/"
                            target="_blank"
                        >
                            <img
                                src="images/pr/coinspeaker_farbe2.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://www.digitaljournal.com/pr/news/surf-reward-launches-browser-extension-with-cryptocurrency-rewards-ido-presale"
                            target="_blank"
                        >
                            <img
                                src="images/pr/digital_journal_farbe.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://www.marketwatch.com/press-release/surf-reward-launches-browser-extension-with-cryptocurrency-rewards-ido-presale-2023-02-17?mod=search_headline"
                            target="_blank"
                        >
                            <img
                                src="images/pr/marketwatch_farbe2.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://finance.yahoo.com/news/surf-reward-launches-browser-extension-183100324.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmRlLw&guce_referrer_sig=AQAAADRUn8VJIeXGbgMkx_cN34C9mSxIM9imYWIVI9hBjlckWF8a2QR5sXEGXFkEKpeJKoq4SGadMeqN-4uLyoe5NANFVG-g3ouSyvMdnFnOhlhTALnxWnoGfaieEi43Fxa1mvQx88WVuIDHsx1NQkkuiQLGLfkZwnyLEFx5Iwx_8Hn3"
                            target="_blank"
                        >
                            <img
                                src="images/pr/yahoo_finance_farbe.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>
                        <a
                            className="item"
                            href="https://zycrypto.com/abelius-capital-unveils-its-surf2earn-web-browser-extension-surf-reward-and-ido-plans/"
                            target="_blank"
                        >
                            <img
                                src="images/pr/zycrypto_farbe.png"
                                loading="eager"
                                alt=""
                                className="item"
                            />
                        </a>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default Partners