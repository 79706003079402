import React from 'react'

function Video() {
    return (
        <section id="how-it-works">
            <div className="section-video bg-tile-top-lg bg-neutral-600 _1-px">
                <div className="wrapper-parent">
                    <div className="margin-top margin-8xl video">
                        <div
                            style={{ paddingTop: "56.17021276595745%" }}
                            className="video-2 w-video w-embed"
                        >
                            <iframe
                                className="embedly-embed"
                                src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FXof9kXTVX0k%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DXof9kXTVX0k&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FXof9kXTVX0k%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
                                scrolling="no"
                                allowFullScreen=""
                                title="SURF Reward - Explanatory video"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Video